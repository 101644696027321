.footer {
  width: 90%;
  font-size: 0.75rem;
  font-weight: lighter;
  position: fixed;
  bottom: 3%;
}
.footer_span {
  color: #008aff;
}
@media screen and (min-width: 600px) {
  .footer {
    transform: scale(2.3);
  }
}
