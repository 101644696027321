@font-face {
  src: url(./asset/fonts/truculenta.ttf);
  font-family: Truculenta;
}
@font-face {
  src: url(./asset/fonts/Truculenta-SemiBold.ttf);
  font-family: Truculenta-SemiBold;
}
* {
  margin: 0;
  padding: 0;
  font-family: Truculenta;
  font-weight: bolder;
}
.container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
