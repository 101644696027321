.message {
  margin: 2.5rem;
  width: 80%;
  height: 6rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.message_h3 {
  font-size: 3rem;
}
.message_p {
  font-size: 1.5rem;
  font-weight: lighter;
}
@media screen and (min-width: 600px) {
  .message {
    margin: auto;
    margin-top: -0.5rem;
    width: 20rem;
    transform: scale(1.5);
  }
}
