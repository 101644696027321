.logo {
  margin-top: -2.5rem;
  width: 18rem;
  height: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logo_img {
  margin-bottom: -1.5rem;
  width: 16rem;
  height: 16rem;
}
.logo_h1 {
  font-weight: 700;
  font-size: 3rem;
  color: #008aff;
  line-height: 3rem;
}
.logo_h2 {
  font-weight: 700;
  font-size: 3.1rem;
  color: #661ab3;
  line-height: 1.5rem;
}
@media screen and (min-width: 600px) {
  .logo {
    transform: scale(1.35);
    margin-top: 2rem;
    margin-bottom: -2rem;
  }
}
