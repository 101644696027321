.timer {
  margin: 1rem;
  height: 6rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.timer_item {
  width: 4rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.timer_item_p {
  margin: 0.5rem 0;
  font-size: 0.8rem;
}
.timer_item_number {
  width: 80%;
  height: 70%;
  box-shadow: 0 0 5px 0px;
  border-radius: 0.5rem;
  background-color: aliceblue;
  display: flex;
  align-items: center;
  justify-content: center;
}
.timer_item_number_p {
  font-size: 3rem;
  line-height: 1;
  font-weight: lighter;
}
@media screen and (min-width: 600px) {
  .timer {
    width: 20rem;
    margin-top: 0.1rem;
    margin: auto;
    transform: scale(2);
  }
}
